.cas-airport-popup-icon{
   padding: 0.1rem 0.4rem 0.2rem 0.3rem !important;
   font-size: 0.9rem !important;
}
.airport-popup-dialog{
   width: 50vw;
   
   .p-dialog-header{
      border: 0px !important;
   }
   .p-dialog-content{
      padding: 0px !important;
   }
   
   .airport-popup-content{
      float: left;
      width: 100%;
      height: 42vh;
      
      .airport-popup-region-container{
         width: 31%;
         float: left;
         height: 100%;
         margin-right: 1rem;
         padding: 0.5rem 1rem;
         background: $color-secondary-background;
         .airport-popup-region{
            background-color: #f4f4f4;
            font-weight: bold;
            padding: 0.5rem 0.5rem;
            margin: 0.8rem 0.4rem;
            border-radius: 0.5rem;
            &:hover{
               background-color: $color-primary-hover;
            }
            &.selected-region {
               background-color: $color-primary-blue;
               color: $color-white;
            }

         }
      }
      .airport-popup-airport-container{
         width: 62%;
         float: right;
         height: 100%;
         padding: 0.5rem 1rem;
         .airport-popup-airport{
            
            width: 47%;
            padding: 0.5rem 0.5rem;
            margin: 0.4rem;
            border-radius: 0.5rem;
            float: left;
            height: 2.5rem;
            
            &:hover{
               background-color: $color-primary-hover;
            }
            &.selected-airport {
               background-color: $color-primary-blue;
               color: $color-white;
            }
            .airport-code{
               font-weight: bold;
            }

         }
      }
   }
}
