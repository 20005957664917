

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    margin-top: 45%;
    margin-left: -172%;
    background-color: #f4f4f4;
      padding: 13%;
      width: 445%;
      border-radius:4px;
      padding-left: 2px;
      justify-content: center;
   
  }
  
  .pagination > .active > a{
    background-color: lightgray ;
    border-color: gray ;
    color: #fff;
    
  }
  .pagination > li > a{
    
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    
    
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: black ;
    border-color: lightgray;
    outline: none ;
  }
  .pagination > li > a, .pagination > li >  span{
    color: gray
  
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: 4px;
  }
  
  .report-data-table-header:not(td),.p-paginator-bottom{
      background-color: $color-table-header !important;
      color: $color-white !important;
  }
  .report-data-table-header > span {
      color: $color-white !important;
  }
  .p-paginator-element >span {
      color: $color-white !important;
  }
  
  .testCasename-header{
      width: 20%;
  }
  .dashboardPadding{
      padding:2%
  }
  .toggeleBtn{
      margin-right: -1.5rem;
  }
  .status-header{
      width: 11.5%;
  }
  .dashboard-layout-logo{
  height: 50px;
  width: 200px;
  }
  .screenshotbox {
      border-radius: 2px;
      padding: .25em .5rem;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: .3px;
  }
  .screenshotbox.screenshotcount {
      background: #d1d4d1;
      color: #020802;
  }
  
  .screenshotitem{
      // overflow-y: scroll;
    
  }
  .shed-displaylist-main-div
  {
      border: 2px solid lightgray;
      margin-left: 12px;
      margin-bottom: 12px;
      width: 95%;
      margin-top: 12px;
      padding: 6px;
  }
  .shed-displaygrid-main-div
  {
      border: 2px solid lightgray;
      margin-left: 6px;
      margin-bottom: 6px;
      width: 95%;
      margin-top: 6px;
      padding: 2px;
  }
  .scheddisplay{
    
      background-color: lightgray;
      border-radius: 4px;
     
      
  }
  .schedspan{
     
      padding: 6px;
      background-color: lightgray;
       margin-bottom: 4px;
       margin-top: 4px;
  }
  .span:active{
    background-color: black ;
    border-color: lightgray;
    outline: none ;
  }
  .gridStyle
  {
      display:flex;
      flex-direction : row;
  }
  .product-item .product-item-content {
      border: 1px solid #D3D3D3;
      border-radius: 3px;
      margin: .3rem;
      text-align: center;
      overflow: auto;
      padding: 2rem 0;
      height: 60vh;   
  }
  .product-item .product-image {
      width: 100%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  
  .p-carousel-container button span{
      font-size: 3rem;
      color: #004164;
  }
  .p-dropdown {
      border-bottom: 2px solid #007AD9;
  }
  
   .totalchart{
      width:300px;
      height:150px
   }
  
  .onhover:hover {
       background-color: #134490;
   }
  .downloadIcon{
      text-align: le
  }
  .flights-view-container{
      
      .flight-number-column-header{
          width: 10.5%;
      }
      .airport-column-header{
          width:10.5%;
      }
      .date-column-header{
          width:11%;
      }
      .time-column-header{
          width:9.5%;
      }
      .res-column-header{
          width:8%;
      }
      .action-column-header{
          width:7rem;
      }
  
      .modified-row{
          background: $color-table-row-edited !important;
      }
  
      .header-contents{
          margin-left: 0.5rem;
      }
  }
  
  .fileName-holder{
      word-wrap: break-word;
  }
  
  .cancelation-results-container{
      .flight-type-header {
          width: 9%;
      }
      .date-header{
          width: 8%;
      }
      .airport-header{
          width: 11%;
      }
      .flight-number-header{
          width: 11%;
      }
      .time-header{
          width: 9.5%;
      }
      .status-header{
          width: 9rem;
      }
      .moduleName-header{
        width: 9rem;
      }
      .action-header{
          width: 6.5rem;
      }
      
      
      
      .action-column{
          padding-top: 0.3rem;
          margin-bottom: -0.2rem;
      }
  }
  .forms-message{
      width: 100%;
  }
  
  .form-container {
      .input-fields-container{
          padding-right: 4rem;
          .form-field-label{
              padding-left: 4rem;
              padding-top: 1rem;
          }
      
      }
  
      .form-field-button{
          border-top: 1px solid #d5d5d5;
          padding-right: 4rem;
          margin-top: 0.5rem;
  
      }
      .status-badge{
          padding: 0.1rem 0.5rem;
          border-radius: 1rem;
          font-weight: bold;
  
          &.pending-badge{
              background-color: #ffd8b2;
              color: #805b36;
          }
          &.approved-badge{
              background-color: #c8e6c9;
              color: #256029;
          }
      
          &.rejected-badge{
              background-color: #ffcdd2;
              color: #c63737;
          }
      }
      
      
  }
  
  .flights-upload-container{
      .p-fileupload-buttonbar{
          width: 30%;
          float: left;
          border-bottom:  1px solid #c8c8c8 !important;
      }
      .p-fileupload-content{
          width: 68%;
          float: left;
          margin-left: 0.5rem;
          min-height: 4.2rem;
      }
  }
  
  
  
  
  .files-uploaded-container{
      .file-name{
          word-wrap: break-word !important;
          width: 80%;
          float: left;
          padding-top: 0.5rem;
      }
      .fileName-delete-btn{
          width: 20%;
          float: left;
          margin-bottom: 0.1rem;
      }
  }
  
  
  
  