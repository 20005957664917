.p-grid.grid-test>[class^="p-col"] {
    background-color: rgb(0, 255, 213);
    border:1px solid black;
    color: black;
    
}

/* Typography */
h1 {
    font-weight: normal;
    margin: 0;
    font-size: 24px;
}

h2 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
}

.card {
    background-color: #ffffff;
    padding: 1.25em;
    margin-bottom: 16px;
    @include border-radius($borderRadius);

    &.card-w-title {
        padding-bottom: 2em;
    }

    h1 {
        margin: 1em 0 .5em 0;
        border-bottom: 1px solid #d5d5d5;
        padding: .1em;
        font-size: 24px;

        &:first-child {
            margin: 0 0 .5em 0;
        }
    }
}

.p-g {
    -ms-flex-wrap: wrap;
}

.cas-dialog {
    border: 0px !important;
    .p-dialog-header{
        background: $color-primary-header !important;
        color: $color-white !important;
    }
    .p-dialog-header-icon {
        color: white !important;
        background: $color-primary-blue !important;
        padding-left: 0.15rem !important;
    }

}

.p-calendar,.p-dropdown {
    width: 100%;
}

.p-datepicker table td{
    padding: 0.1rem !important;
}
.p-datepicker-multiple-month .p-datepicker-group-container{
    min-width: 60rem;
}
.p-calendar-w-btn .p-datepicker-trigger{
    width: 2.6rem !important;
}
.p-calendar .p-inputtext{
    width: 100% !important;
}

// .p-datatable .p-datatable-header{
//    background: $color-table-header;
// }

