.layout-topbar {
  position: fixed;
  height: $topbarHeight;
  padding: 0.5rem;
  color: #ffffff;
  z-index: 1000;
  right: 0;
  @include clearfix();
  @include linear-gradient-left($topbarLeftBgColor, $topbarRightBgColor);
  @include transition(left $transitionDuration);

  .layout-topbar-icons {
    float: right;
    display: block;
    padding-top: 0.4rem;
    -moz-animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;

    .user-icon {
      img {
        float: left;
        height: 3rem;
      }
    }
    .dropdown-content li {
      text-decoration: none;
      text-align: left;
      list-style: none;
      padding-left: 0px;

      button {
        display: block;
        width: 100%;
        height: 100%;
        border: 0 none;
        padding: 10px 16px;
        margin: 0px;
        border-radius: 0;
        cursor: pointer;
        @include transition(color $transitionDuration);

        &:hover {
          color: $menuitemHoverColor;
        }

        span {
          margin-left: 0.25em;
          font-size: 1.1rem;
          vertical-align: middle;
        }

        i {
          vertical-align: middle;
        }
      }
    }
    button {
      position: relative;
      color: $topbarItemColor;
      padding: 5px 10px;
      display: inline-block;
      text-decoration: none;
      @include transition(color $transitionDuration);
      overflow: visible;

      &:hover {
        color: $topbarItemHoverColor;
      }

      span {
        &.layout-topbar-icon {
          font-size: 2rem;
        }
        &.layout-topbar-username {
          padding: 0.2rem 0.5rem;
          display: inline-block;

          vertical-align: top;
          font-size: 1.8rem;
        }
        &.layout-topbar-item-text {
          display: inline-block;
          font-size: 1.3rem;
          padding-right: 0.3rem;
        }

        &.layout-topbar-badge {
          position: absolute;
          font-size: 10px;
          right: -5px;
          top: -5px;
          width: 16px;
          height: 16px;
          text-align: center;
          line-height: 16px;
          color: $topbarItemBadgeColor;
          background-color: $topbarItemBadgeBgColor;
          @include border-radius(50%);
        }
      }
    }

    .layout-topbar-search {
      padding: 0;
      position: relative;
      display: inline-block;
      padding-top: 0.3rem;

      input {
        display: inline-block;
        border: 0 none;
        font-size: $fontSize;
        background: transparent;
        border-bottom: 2px solid $topbarSearchInputBorderBottomColor;
        outline: 0 none;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $topbarSearchInputColor;
        width: 12rem;
        padding: 1px 2rem 1px 1px;
        margin: 0px;
        @include border-radius(2px);

        &::-webkit-input-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          @include transition(opacity $transitionDuration);
        }
        &:-moz-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          @include transition(opacity $transitionDuration);
        }
        &::-moz-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          @include transition(opacity $transitionDuration);
        }
        &:-ms-input-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          @include transition(opacity $transitionDuration);
        }
      }

      .layout-topbar-search-icon {
        font-size: 1.2rem;
        position: absolute;

        right: 0px;
      }

      &:hover {
        input {
          border-bottom-color: $topbarItemHoverColor;
          &::-webkit-input-placeholder {
            opacity: 1;
          }
          &:-moz-placeholder {
            opacity: 1;
          }
          &::-moz-placeholder {
            opacity: 1;
          }
          &:-ms-input-placeholder {
            opacity: 1;
          }
        }

        .layout-topbar-search-icon {
          color: $topbarItemHoverColor;
        }
      }
    }
  }
}
.layout-topbar-left {
  position: fixed;
  height: $topbarHeight;
  // width: 20rem;
  padding: 0.6rem 0;
  // left:39.5%;
  text-align: center;
  color: #ffffff;
  z-index: 1001;
  background-color: $menuDarkBgColorFirst;
  @include clearfix();
  @include transition(left $transitionDuration);

  .layout-topbar-logo-name {
    font-size: 3rem;
  }
  .layout-menu-button {
    cursor: pointer;
    text-decoration: none;
    top: 0.1rem;
    color: $topbarItemColor;
    @include transition(color $transitionDuration);
    float: right;
    margin-right: -2rem;

    span {
      font-size: 2rem;
      border: 1px solid $color-primary-blue;
      border-radius: 1.8rem;
      background: $color-primary-blue;
      width: 3rem;
      height: 3rem;
      padding-top: 0.4rem;
      padding-left: 0.4rem;
    }

    &:hover {
      color: $topbarItemHoverColor;
    }
  }

  button {
    cursor: pointer;

    padding: 0;
    position: relative;
    display: inline-block;
    bottom: 0.4rem;
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;

  width: 10rem;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #3e464c;
  min-width: 200px;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/*
.dropdown-content li:hover {background-color: #ddd;} */

.dropdown:hover .dropdown-content {
  display: block;
}
