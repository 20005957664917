.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    visibility: visible;
    position: absolute;
    top: calc(100% + 12px); 
    left: 50%;
    transform: translateX(-50%);
    background-color: #007AD9;
    color: white;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0.9;
    z-index: 1;
    transition: opacity 0.3s ease;
    text-transform: none;
  }
  
  .tooltip-container:hover .tooltip {
    visibility: visible;
  }
  
  .tooltip-container:hover .tooltip {
    opacity: 1;
  }
  