/* General */
$fontSize:14px;
$bodyBgColor:#edf0f5;
$textColor:#333333;
$textSecondaryColor:#707070;
$borderRadius:3px;
$dividerColor:#e3e3e3;
$transitionDuration:.2s;
$maskBgColor:#424242;
$color-white:white;
$color-primary-blue:#004164;
$color-primary-header:#2b3135;
$color-primary-hover:#e9ecef;
$color-secondary-background:#777d81;
$color-table-header:#3e464c;
$color-table-row-edited:#f99e9e;

/* Menu Common */
$menuitemBadgeBgColor:#004164;
$menuitemBadgeColor:#ffffff;
$submenuFontSize:13px;
$menuitemActiveRouteColor:#1fa1fc;

/* Menu Light */
$menuBgColorFirst:#f3f4f9;
$menuBgColorLast:#d7dbe8;
$menuitemColor:#232428;
$menuitemHoverColor:#00aaad;
$menuitemActiveColor:#00aaad;
$menuitemActiveBgColor:#ffffff;
$menuitemBorderColor:rgba(207, 211, 224, 0.6);

/* Menu Dark */
$menuDarkBgColorFirst:#004164;
$menuDarkBgColorLast:#004164;
$menuitemDarkColor:#ffffff;
$menuitemDarkHoverColor:#00aaad;
$menuitemDarkActiveColor:#00aaad;
$menuitemDarkActiveBgColor:#2e3035;
$menuitemDarkBorderColor:rgba(52, 56, 65, 0.6);

/* Topbar */

$topbarLeftBgColor:#004164;
$topbarRightBgColor:#004164;
$topbarItemBadgeBgColor:#ef6262;
$topbarItemBadgeColor:#ffffff;
$topbarItemColor:#ffffff;
$topbarItemHoverColor:#c3e8fb;
$topbarSearchInputBorderBottomColor:#ffffff;
$topbarSearchInputColor:#ffffff;
$topbarHeight:4.5rem;
$topbarHeightMainMargin:86px;


/* Topbar */
$leftbarWidth:10rem;

/* Footer */
$footerBgColor:#ffffff;


