/* ToastDemo.css */
/* Solid border */
hr.solid {
  border-top: 3px solid #007ad9;
}
.toast-demo button {
    min-width: 10rem;
    margin-right: .5rem;
}

@media screen and (max-width: 960px) {
    .toast-demo button {
        width: 100%;
        margin-bottom: .5rem;
    }
}
              