*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
    font-size: 87.5%;
  }



body {
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-size: $fontSize;
    color: $textColor;
    background-color: $bodyBgColor;
    margin: 0;
    padding: 0;
    min-height: 100%;
    height: 100%;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

.layout-wrapper {
    padding: 0;
    min-height: 100vh;
}

.layout-main {
    @include transition(margin-left $transitionDuration);
    padding: 6rem 1.6rem 1.6rem 1.6rem;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

