.dashboard {
    .header-label{
        font-size: 2rem;
        font-weight: bold;
    }

.icon-warning {
background: url("../../../assets/images/sprite.png") -30px -89px no-repeat;;
  width: 18px;
  height: 18px;
}
.icon-alert {
  background: url("../../../assets/images/sprite.png") -5px -89px no-repeat;
  width: 18px;
  height: 18px;
}
.icon-success {
 background: url("../../../assets/images/sprite.png") -57px -89px no-repeat;
  width: 18px;
  height: 18px;
}
    .summary {
        position: relative;
        
        .title {
            font-size: 1.1rem;
            font-weight: bold;
        }
        
        .detail {
            color: $textSecondaryColor;
            display: block;
            margin-top: 10px;
        }
        
        .count {
            color: #ffffff;
            position: absolute;
            top: 0.5rem;
            right: 1rem;
            font-size: 1.5rem;
            padding: 0.5rem 0.7rem;
            @include border-radius($borderRadius);
            
            &.green{
                background-color: #20d077;
            }
            
            &.yellow {
                background-color: #f9c851;
            }
            
            &.blue {
                background-color: #007be5;
            }
            &.red {
                background-color: rgb(239, 98, 98);
            }
        }

        .info {
            color: #ffffff;
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            font-size: 0.9rem;
            padding: 0.4rem 0.7rem;
            @include border-radius($borderRadius);
            
            &.aqua {
                background-color: #7B83EB;
            }
        }
    }

    .highlight-box {
        height: 100px;
        @include clearfix();
        
        .initials {
            height: 100%;
            float: left;
            width: 40%;
            text-align: center;
            padding: 1em;
            padding-top: 1.5rem;

            > span {
                font-size: 3.5rem;
            }
        }
        
        .highlight-details {
            height: 100%;
            background-color: #ffffff;
            float: left;
            width: 60%;
            padding: 1em;

            i {
                font-size: 1.5rem;
                vertical-align: middle;
                margin-right: .25em;
            }
            
            .count {
                color: $textSecondaryColor;
                font-size: 2.5rem;
                display: block;
                text-align: center;
            }
        }
    }

    .line-chart{
        height: 22.5rem;
        padding-top: 2rem;
    }
    
    .task-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        
        li {
            padding: .5em .25em;
            border-bottom: 1px solid $dividerColor;
            @include clearfix();
        }
        
        .p-checkbox {
            vertical-align: middle;
            margin-right: .5em;
        }
        
        .task-name {
            vertical-align: middle;
        }
        
        i {
            float: right;
            font-size: 24px;
            color: $textSecondaryColor;
        }

        .p-panel-content {
            min-height: 256px;
        }
    }

    .contact-form {
        .p-panel-content {
            min-height: 256px;
        }
    }

    
    .p-panel .p-panel-header{
        color: $color-white !important;
        background-color: $color-table-header !important;

    }
    .task-holder .p-panel .p-panel-content{
        min-height: 23.3rem;
    }
}