.cas-input-field{
    width: 90%;
    &:not(:last-child)
    {
        margin-bottom: 1.5rem;
    }
}
.p-inputtext{
    width: 100%;
}
.cas-inline-err-text{
    color: red;
    padding-top: 0.2rem;
}